.App {
  text-align: center;
  font-family: 'Press Start 2P';
}


.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(173, 24, 24);
}

.App-link {
  color: #15df9b;
}